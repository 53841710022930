import { AppSelector, RootState } from 'app/store'
import { allKey } from 'app/constants'
import {
  arrayIfEmpty,
  arrayIfNil,
  arrayIfNilOrEmpty,
  emptyArr,
  ensureArray,
  isNilOrEmpty,
  paramsCartesianProduct,
} from 'app/utils/fp'
import { dataStoreKey, paramsStoreKey, ParamsType } from 'core/caching/cacheReducers'
import { IDataKeys } from 'k8s/datakeys.model'
import {
  either,
  equals,
  filter,
  find,
  identity,
  isEmpty,
  isNil,
  pickAll,
  pick,
  pipe,
  reject,
  whereEq,
} from 'ramda'
import { createSharedSelector, selectParamsFromProps } from 'core/utils/selectorHelpers'
import { memoize, memoizedObj, memoizeShallow, generateObjMemoizer } from 'utils/misc'
import { sessionStoreKey } from 'core/session/sessionReducers'
import { GlobalParams } from 'core/hooks/useGlobalParams'
import { preferencesStoreKey } from 'core/session/preferencesReducers'

interface SelectorParams {
  useGlobalParams?: boolean
}

const selectParams = selectParamsFromProps<SelectorParams>()
const arrayizeIndexBy = (args: [string, string | string[]]) => {
  const [dataKey, indexBy = emptyArr]: [string, string[] | string] = args
  return [dataKey, ensureArray(indexBy)]
}

const memoizeCombinations = memoize((params) => paramsCartesianProduct(params))

const findIndexedParamsItem = (params) => {
  const memoizedParams = memoizedObj(params)
  const combinations = memoizeCombinations(memoizedParams)
  return (item) => {
    return combinations.some((combination) => {
      return whereEq(combination)(item)
    })
  }
}

const findIndexedParamsCollection = (params) => {
  const memoizedParams = memoizedObj(params)
  const combinations = memoizeCombinations(memoizedParams)
  return (storeData) => {
    return combinations.some((combination) => {
      return !!find(whereEq(combination))(storeData)
    })
  }
}

const getDataSelector = memoizeShallow(
  <T extends keyof IDataKeys>(
    dataKey: T,
    indexBy: string | string[] = emptyArr,
    globalParamKeys?: Array<keyof GlobalParams>,
  ) => {
    const globalParamKeysMemo = generateObjMemoizer<Partial<GlobalParams>>()
    const selectGlobalParams = (state: RootState): IDataKeys[T] => {
      if (!globalParamKeys) {
        return null
      }
      const { username } = state[sessionStoreKey]
      const allPrefs = state[preferencesStoreKey]
      const userPrefs = allPrefs[username]
      return globalParamKeysMemo(pick(globalParamKeys, userPrefs?.globalParams || {}))
    }
    const selectAllData = (state: RootState): IDataKeys[T] => {
      return arrayIfNilOrEmpty(state.cache[dataStoreKey][dataKey])
    }
    const selectAllParams = (state: RootState): ParamsType[] =>
      arrayIfNilOrEmpty(state.cache[paramsStoreKey][dataKey])
    const dataSelector: AppSelector<IDataKeys[T]> = createSharedSelector(
      selectAllData,
      selectAllParams,
      selectParams,
      selectGlobalParams,
      (storeData, storeParams, params, globalParams) => {
        // set ignoreGlobalParams false only if explicitly set false
        const ignoreGlobalParams = params?.useGlobalParams === false
        const providedIndexedParams = pipe<ParamsType, ParamsType, ParamsType>(
          pickAll(ensureArray(indexBy)),
          reject(either(isNil, equals(allKey))),
        )(params as ParamsType)
        // If the provided params are already cached
        // Search either for the provided params or an empty object (it means we fetched for ALL items)
        if (
          isNilOrEmpty(indexBy) ||
          findIndexedParamsCollection(providedIndexedParams)(storeData)
        ) {
          const filterByParams = isEmpty(providedIndexedParams)
            ? identity
            : filter(findIndexedParamsItem(providedIndexedParams))
          const filterByGlobalParams =
            !globalParams || ignoreGlobalParams
              ? identity
              : (data) =>
                  globalParamKeys.reduce((accFilteredData, globalParamKey) => {
                    const globalParamValue = globalParams[globalParamKey]
                    if (isNilOrEmpty(globalParamValue) || globalParamValue === allKey) {
                      return accFilteredData
                    }
                    // commenting this changes for optimization
                    // return accFilteredData.filter((row) =>
                    //   Array.isArray(globalParamValue)
                    //     ? globalParamValue.includes(row[globalParamKey])
                    //     : globalParamValue === row[globalParamKey],
                    // )

                    const globalParamValueSet = new Set(globalParamValue)
                    return accFilteredData.filter((row) =>
                      globalParamValueSet.has(row[globalParamKey]),
                    )
                  }, data)

          // Return the cached data filtering by the provided params
          return pipe<
            IDataKeys[T] | [],
            IDataKeys[T] | [],
            IDataKeys[T] | [],
            IDataKeys[T] | [],
            IDataKeys[T] | []
          >(
            arrayIfNil,
            // Filter the data by the provided params
            filterByParams,
            filterByGlobalParams,
            // Return the constant emptyArr to avoid unnecessary re-renderings
            arrayIfEmpty,
          )(storeData)
        }
        return emptyArr
      },
    )
    return dataSelector
  },
  { transformArgs: arrayizeIndexBy },
)

export default getDataSelector
