import { RootState } from 'app/store'
import { createSelectorCreator, weakMapMemoize, lruMemoize } from 'reselect'
// import { createSelectorCreator, defaultMemoize } from 'reselect'
import { emptyObj, objIfNilOrEmpty } from 'app/utils/fp'
import { mergeLeft } from 'ramda'
import { memoizedObj } from 'utils/misc'
import { moizeMaxSize } from 'app/constants'
import isEqual from 'lodash.isequal'
import { equals } from 'ramda'

export const selectParamsFromProps = <P>(defaultParams = emptyObj as P) => (
  _: RootState,
  params: P = emptyObj as P,
): P => {
  // @fixme fix these typings
  // @ts-ignore
  return objIfNilOrEmpty(memoizedObj(mergeLeft(params, defaultParams))) as P
}

// const hashFn = (...args) => args.reduce((acc, val) => acc + '-' + JSON.stringify(val), '')

// export const createSharedSelector = createSelectorCreator(weakMapMemoize)

export const createSharedSelector = createSelectorCreator(lruMemoize, {
  equalityCheck: equals,
  maxSize: moizeMaxSize,
  resultEqualityCheck: equals,
})
