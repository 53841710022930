import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mergeLeft, over, lensPath, pathOr, set, dissocPath } from 'ramda'
import { imageUrls, UserPreferences } from 'app/constants'
import { CloudDefaults } from 'app/plugins/infrastructure/components/cloudProviders/model'

const initialState = {
  logoUrl: imageUrls.logoPrimary,
  logoSrc: '',
  logoFileName: '',
  global: {},
}

const {
  name: preferencesStoreKey,
  reducer: preferencesReducers,
  actions: preferencesActions,
} = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    updateLogo: (state, { payload }) => {
      return {
        ...state,
        logoUrl: payload.logoUrl,
        logoSrc: payload.logoSrc,
        logoFileName: payload.logoFileName,
      }
    },
    // @ts-ignore
    resetGlobalPrefs: (state) => {
      return {
        ...state,
        logoUrl: '',
        logoSrc: '',
        logoFileName: '',
      }
    },
    updateGlobalPrefs: (state, { payload }: PayloadAction<GlobalPreferenceState>) => {
      const existingPrefs = pathOr({}, ['global'], state)
      return { ...state, global: { ...existingPrefs, ...payload } }
    },
    updatePrefs: (state, { payload }: PayloadAction<PreferencesActionPayload>) => {
      const path = Array.isArray(payload.key)
        ? [payload.username].concat(payload.key)
        : [payload.username, payload.key]
      return over(lensPath(path), mergeLeft(payload.prefs), state)
    },
    setPrefs: (state, { payload }: PayloadAction<PreferencesActionPayload>) => {
      const path = Array.isArray(payload.key)
        ? [payload.username].concat(payload.key)
        : [payload.username, payload.key]
      return set(lensPath(path), payload.prefs, state)
    },
    removePrefs: (state, { payload }: PayloadAction<PreferencesActionPayload>) => {
      const path = Array.isArray(payload.key)
        ? [payload.username].concat(payload.key)
        : [payload.username, payload.key]
      return dissocPath(path, state)
    },
    resetUserPrefs: (state) => {
      return {
        logoUrl: state.logoUrl,
        global: state.global,
        logoSrc: '',
        logoFileName: '',
      }
    },
  },
})

export { preferencesStoreKey, preferencesActions }
export default preferencesReducers

export interface GlobalPreferencesActionPayload {
  key: string
  value: string | { [key: string]: any }
}
export interface PreferencesActionPayload {
  username: string
  key: string | string[]
  prefs?: { [key: string]: { [key: string]: any } }
}

interface UserAwsPreferences {
  [CloudDefaults.Region]: string
  [CloudDefaults.RegionLabel]: string
  [CloudDefaults.Domain]: string
  [CloudDefaults.DomainLabel]: string
  [CloudDefaults.SshKey]: string
}
interface UserAzurePreferences {
  location: string
  [CloudDefaults.Region]: string
  [CloudDefaults.SshKey]: string
}
interface CloudProviderDefaults {
  [clusterId: string]: UserAwsPreferences | UserAzurePreferences
}
interface UserDefaultsByPreference {
  [UserPreferences.FeatureFlags]: { isOnboarded: boolean }
  [UserPreferences.Dashboard]: { cards: string[]; isInitialized: boolean }
  [UserPreferences.Cluster]: { uuid: string }
  [UserPreferences.Namespace]: { namespace: string }
  [UserPreferences.CloudProviders]: CloudProviderDefaults
}

interface GlobalPreferenceState {
  theme?: any
  customer_external_uuid?: string
  featureFlags?: GlobalFeatureFlags
}

interface GlobalFeatureFlags {
  isOnboarded: boolean
}

interface UserPreferenceState {
  root: {
    currentTenant: string
    lastStack: string
    currentRegion: string
    rowsPerPage?: string
  }
  defaults: UserDefaultsByPreference
}
interface UserPreferencesMap {
  [key: string]: UserPreferenceState
}

export type PreferencesState = {
  logoUrl?: string
  global?: GlobalPreferenceState
} & UserPreferencesMap
